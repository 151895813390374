import {
    PROFILE_CHANGE_PASSWORD_SUCCESS,
    PROFILE_CHANGE_PASSWORD_FAIL,
    PROFILE_SET_CHANGE_PASSWORD_INITIAL,
    LOGOUT_SUCESSS,
} from "./types";

import { apiPost } from "../api";
import { logout } from './auth';

export const setState = () => async (dispatch) => {
    dispatch({ type: PROFILE_SET_CHANGE_PASSWORD_INITIAL, payload: [] });
};

export const updatePassword = (model) => async (dispatch) => {
    try {

        let result = await apiPost("/api/Profile/changepassword", model);
        if (result.commit) {
            dispatch({
                type: PROFILE_CHANGE_PASSWORD_SUCCESS,
                payload: {
                    confirmMessage: result.confirm_Message,
                },
            });
            setTimeout(() => {
                dispatch({ type: LOGOUT_SUCESSS });
                window.location.href = "/login";
            },
                3000);


        } else {
            dispatch({
                type: PROFILE_CHANGE_PASSWORD_FAIL,
                payload: {
                    confirmMessage: result.confirm_Message,
                },
            });
        }
    } catch (error) {
        dispatch({ type: PROFILE_CHANGE_PASSWORD_FAIL });
    }
};

