import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

//Componentes
import Login from "../auth/Login";
import Layout from "../layout/Layout"
import RecuperarContrasena from "../auth/RecuperarContrasena";
import { Spinner } from "../../components/common/spinner";

//Pages
import {
    HomeResultados,
    ProfileChangePassword
} from "../../pages";
import CambiarContrasena from "../auth/CambiarContrasena";

const Routes = ({ auth: { isAuthenticated } }) => {
    return (

        <Switch>
            <Route exact path={["/", "/usercambiopass"]}>
                <Layout>
                    <Switch>
                        <PrivateRoute exact path="/" component={HomeResultados}></PrivateRoute>
                        <PrivateRoute exact path="/usercambiopass" component={ProfileChangePassword}></PrivateRoute>
                    </Switch>
                </Layout>
            </Route>
            {/*<Layout>*/}
            {/*    <PrivateRoute exact path="/" component={HomeResultados}></PrivateRoute>*/}
            {/*    <Spinner />*/}
            {/*</Layout>*/}
            <Route exact path={["/login", "/recuperarcontrasena", "/cambiarcontrasena/:key"]}>
                <Switch>
                    <Route exact path="/login" component={Login}></Route>
                    <Route
                        exact
                        path="/recuperarcontrasena"
                        component={RecuperarContrasena}
                    ></Route>
                    <Route
                        exact
                        path="/cambiarcontrasena/:key"
                        component={CambiarContrasena}
                    ></Route>
                </Switch>
            </Route>
        </Switch>
    );
};

Routes.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(Routes);
