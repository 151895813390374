import {
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_TOKEN_SUCCESS,
    AUTH_TOKEN_FAIL,
    LOGOUT_SUCESSS,
    REMOVE_CONFIRM_MESSAGE,
    PASSWORD_RECOVER_SUCCESS,
    PASSWORD_RECOVER_FAIL,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,
    GET_CHANGE_PASSWORD_FAIL,
    GET_CHANGE_PASSWORD_SUCCESS,
    RECUPERAR_PASSWORD_SET_INITIAL
} from "../action/types";

const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: false,
    user: null,
    confirmMessage: null,
    typeMessage: "text-danger",
    emailSend: false,
    isChangePassword: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case RECUPERAR_PASSWORD_SET_INITIAL:
            return {
                ...state,
                ...payload,
                emailSend: false,
            };

        case AUTH_SUCCESS:
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
            };

        case AUTH_TOKEN_SUCCESS:
            return {
                ...state,
                username: payload.UserName,
                email: payload.Email,
                isAuthenticated: true,
            };

        case LOGOUT_SUCESSS:
            localStorage.removeItem("token");
            return {
                ...state,
                isAuthenticated: false,
                confirmMessage: "",
                typeMessage: "text-danger",
            };

        case AUTH_FAIL:
        case PASSWORD_RECOVER_FAIL:
        case CHANGE_PASSWORD_FAIL:
        case GET_CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                ...payload,
                typeMessage: "text-danger",
                isAuthenticated: false,
                emailSend: false,
                isChangePassword: false,
                confirmMessage: payload.confirmMessage,
            };
        case AUTH_TOKEN_FAIL:
            return {
                token: null,
                isAuthenticated: false,
            };

        case REMOVE_CONFIRM_MESSAGE:
            return {
                ...state,
                typeMessage: "text-danger",
                confirmMessage: "",
            };
        case PASSWORD_RECOVER_SUCCESS:
            return {
                ...state,
                confirmMessage: payload.confirmMessage,
                typeMessage: payload.typeMessage,
                emailSend: true,
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                confirmMessage: payload.confirmMessage,
                typeMessage: payload.typeMessage,
                isChangePassword: true,
            };
        case GET_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                item: payload,
            };
        default: {
            return state;
        }
    }
}
