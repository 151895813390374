export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_TOKEN_SUCCESS = "AUTH_TOKEN_SUCCESS";
export const AUTH_TOKEN_FAIL = "AUTH_TOKEN_FAIL";
export const LOGOUT_SUCESSS = "LOGOUT_SUCESSS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const REMOVE_CONFIRM_MESSAGE = "REMOVE_CONFIRM_MESSAGE";
export const PASSWORD_RECOVER_SUCCESS = "PASSWORD_RECOVER_SUCCESS";
export const PASSWORD_RECOVER_FAIL = "PASSWORD_RECOVER_FAIL";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const GET_CHANGE_PASSWORD_SUCCESS = "GET_CHANGE_PASSWORD_SUCCESS";
export const GET_CHANGE_PASSWORD_FAIL = "GET_CHANGE_PASSWORD_FAIL";
export const RECUPERAR_PASSWORD_SET_INITIAL = "RECUPERAR_PASSWORD_SET_INITIAL";

/** RESULTADOs*/
export const RESULTADO_SETEAR_LISTA = "RESULTADO_SETEAR_LISTA";
export const RESULTADO_GET_SUCCESS = "RESULTADO_GET_SUCCESS";
export const RESULTADO_GET_FAIL = "RESULTADO_GET_FAIL";
export const RESULTADO_LISTA_SUCCESS = "RESULTADO_LISTA_SUCCESS";
export const RESULTADO_LISTA_FAIL = "RESULTADO_LISTA_FAIL";
export const RESULTADO_LISTA_MAS_SUCCESS = "RESULTADO_LISTA_MAS_SUCCESS";
export const RESULTADO_LISTA_MAS_FAIL = "RESULTADO_LISTA_MAS_FAIL";
/** fin de RESULTADOs*/

/** Profile*/
export const PROFILE_SET_CHANGE_PASSWORD_INITIAL = "PROFILE_SET_CHANGE_PASSWORD_INITIAL";
export const PROFILE_CHANGE_PASSWORD_SUCCESS = "PROFILE_CHANGE_PASSWORD_SUCCESS";
export const PROFILE_CHANGE_PASSWORD_FAIL = "PROFILE_CHANGE_PASSWORD_FAIL";
/** end Profile*/