import {
    RESULTADO_SETEAR_LISTA,
    RESULTADO_LISTA_SUCCESS,
    RESULTADO_LISTA_FAIL,
    RESULTADO_LISTA_MAS_SUCCESS,
    RESULTADO_LISTA_MAS_FAIL,
} from "../action/types";

const initialState = {
    items: [],
    error: null,
    item: {},
    loading: true,
    disabled: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case RESULTADO_SETEAR_LISTA:
            return {
                ...state,
                items: payload,
                disabled: false,
            };
        case RESULTADO_LISTA_SUCCESS:
            return {
                ...state,
                error: null,
                items: payload,
                disabled: payload.length < 35,
            };

        case RESULTADO_LISTA_MAS_SUCCESS:
            return {
                ...state,
                error: null,
                items: [...state.items, ...payload],
                disabled: payload.length < 35,
            };
      

        case RESULTADO_LISTA_FAIL:
        case RESULTADO_LISTA_MAS_FAIL:
            return {
                ...state, 
                error: true,
                loading: false,
            };

        default:
            return state;
    }
}
