import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { Oval } from "react-loader-spinner";
import "./spinner.css";

export const Spinner = (props) => {
    const { promiseInProgress } = usePromiseTracker();
    
  return (
    (promiseInProgress) && (
      <div className="spinner">
              <Oval color="#5F6369" secondaryColor="#5F6369" height="150" width="150" />
      </div>
    )
  );
};

