import 'react-datepicker/dist/react-datepicker.css'
import React, { useState, useEffect, Fragment } from "react";
import { connect, useSelector } from "react-redux"
import PropTypes from "prop-types";

import {
    Card, CardBody, CardTitle, Row, Col,
    Button, FormGroup, Label
} from 'reactstrap'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import style from './profile.user.change.password.module.css';
import { updatePassword, setState } from "../../action/profile";
import { logout } from "../../action/auth";


const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
        .required(
            "La contraseña actual es requerida."
        ),
    newPassword: Yup.string()
        .min(
            8,
            "La contraseña debe tener mayúsculas y minúsculas, Números, caracteres especiales como !#@$&* y un mínimo de 8 caracteres. Ejemplo. Tj@2039Hw!"
        )
        .required(
            "La contraseña debe tener mayúsculas y minúsculas, Números, caracteres especiales como !#@$&* y un mínimo de 8 caracteres. Ejemplo. Tj@2039Hw!"
        )
        .matches(
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[!#@$&*])/,
            "La contraseña debe tener mayúsculas y minúsculas, Números, caracteres especiales como !#@$&* y un mínimo de 8 caracteres. Ejemplo. Tj@2039Hw!"
        ),
    confirmPassword: Yup.string()
        .when("newPassword", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("newPassword")],
                "La contraseña y la confirmación no coinciden."
            ),
        })
        .required("Es requerido confirmar la contraseña."),
});

const ProfileChangePassword = ({ updatePassword, setState, logout }) => {
    useEffect(() => {
        setState();
    }, [setState]);

    let [myConfig, setMyConfig] = useState(window.myConfig);
    let errorMessage = useSelector((state) => state.profile.confirmMessage);
    let typeMessage = useSelector((state) => state.profile.typeMessage);

    return (
        <div>
            <Card className={style.homeCard}>
                <CardBody>
                    <CardTitle className={style.homeCardTitle} style={{ color: myConfig.brandingColor }} tag="h5">
                        Cambia tu contraseña
                    </CardTitle>
                    <hr />

                    <Formik
                        initialValues={{
                            currentPassword: "",
                            newPassword: "",
                            confirmPassword: "",
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            let model = {
                                CurrentPassword: values.currentPassword,
                                NewPassword: values.newPassword
                            };
                            updatePassword(model);
                            setSubmitting(false);
                        }}
                        validationSchema={validationSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <Form style={{ width: "100%" }} >
                                <Row>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label for="currentPassword">
                                                Contraseña actual
                                            </Label>
                                            <Field
                                                id="currentPassword"
                                                type="password"
                                                name="currentPassword"
                                                autoComplete="chrome-off"
                                                placeholder="Contraseña actual"
                                                className={`form-control ${touched.currentPassword && errors.currentPassword
                                                    ? "is-invalid"
                                                    : touched.currentPassword && !errors.currentPassword
                                                        ? "is-valid"
                                                        : ""
                                                    }`}
                                            />
                                            <ErrorMessage
                                                component="p"
                                                name="currentPassword"
                                                className="text-danger mt-3"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label for="currentPassword">
                                                Nueva contraseña
                                            </Label>
                                            <Field
                                                id="newPassword"
                                                type="password"
                                                name="newPassword"
                                                autoComplete="chrome-off"
                                                placeholder="Nueva contraseña"
                                                className={`form-control ${touched.newPassword && errors.newPassword
                                                    ? "is-invalid"
                                                    : touched.newPassword && !errors.newPassword
                                                        ? "is-valid"
                                                        : ""
                                                    }`}
                                            />
                                            <ErrorMessage
                                                component="p"
                                                name="newPassword"
                                                className="text-danger mt-3"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label for="currentPassword">
                                                Confirmar nueva contraseña
                                            </Label>
                                            <Field
                                                id="confirmPassword"
                                                type="password"
                                                name="confirmPassword"
                                                autoComplete="chrome-off"
                                                placeholder="Confirmar nueva contraseña"
                                                className={`form-control ${touched.confirmPassword && errors.confirmPassword
                                                    ? "is-invalid"
                                                    : touched.confirmPassword && !errors.confirmPassword
                                                        ? "is-valid"
                                                        : ""
                                                    }`}
                                            />
                                            <ErrorMessage
                                                component="p"
                                                name="confirmPassword"
                                                className="text-danger mt-3"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="9">
                                        {errorMessage ? (
                                            <p className={`${typeMessage} mt-3`} role="alert">
                                                {errorMessage}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                    </Col>
                                    <Col lg="3" style={{ textAlign: "right" }}>
                                        <Button className={style.buttonHome} style={{ backgroundColor: myConfig.brandingColor, borderColor: myConfig.brandingColor }}
                                            type="submit" >
                                            Actualizar contraseña
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </div>
    );
};

ProfileChangePassword.propTypes = {};

const mapStateToProps = (state) => ({
    updatePassword: PropTypes.object.isRequired,
    setState: PropTypes.object,
});

const mapDispatchToProps = {
    updatePassword,
    setState,
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileChangePassword);

