import React, { useState, useEffect} from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { recuperarContrasena, removeConfirmMessage, setState } from "../../action/auth";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email("El formato del correo electrónico es incorrecto")
        .required("El correo electrónico es requerido"),
});

const RecuperarContrasena = ({
    auth,
    isAuthenticated,
    recuperarContrasena,
    history,
    setState,
}) => {

    require('./Login.css')
    let errorMessage = useSelector((state) => state.auth.confirmMessage);
    let typeMessage = useSelector((state) => state.auth.typeMessage);
    let emailSend = useSelector((state) => state.auth.emailSend);
    let [myConfig, setMyConfig] = useState(window.myConfig);
    useEffect(() => {
        setState();
    }, [setState]);

    return (
        <div className="login-body" style={{ width: '100%', height: '100%' }}>
            <div className="container container-login" id="container" >
               
                <div className="form-container sign-in-container">
                    <div style={{ display: emailSend ? "none" : "flex" }} className="form-login">
                        <img src={myConfig.brandinglogoBase64} style={{ marginBottom: "25px" }} width="50%" />
                        <h1 className="h1-login " style={{ color: myConfig.brandingColor, fontSize: '2rem' }}>Portal de Pacientes</h1>
                        <div className="social-container">
                        </div>
                        <div style={{ color: "#5F6369", width: '100%', textAlign: "left"}}>
                            <p >Recuperar contraseña</p>
                        </div>
                        <Formik
                            initialValues={{
                                email: "",
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                let model = {
                                    Email: values.email,
                                };
                                recuperarContrasena(model, history);
                                setSubmitting(false);
                            }}
                            validationSchema={validationSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form style={{ width: "100%" }} className="user">

                                    <Field
                                        type="text"
                                        name="email"
                                        autoComplete="chrome-off"
                                        placeholder="Correo electrónico"
                                        className={`input-login ${touched.email && errors.email
                                            ? "is-invalid"
                                            : touched.email && !errors.email
                                                ? "is-valid"
                                                : ""
                                            }`}
                                    />
                                    <ErrorMessage
                                        component="p"
                                        name="email"
                                        className="text-danger mt-3"
                                    />

                                    <button
                                        className="button-login mt-3"
                                        type="submit" style={{ backgroundColor: myConfig.brandingColor, borderColor: myConfig.brandingColor }}
                                    >
                                        Recuperar contraseña
                                    </button>
                                </Form>
                            )}
                        </Formik>
                        {errorMessage ? (
                            <p className={`${typeMessage} mt-3`} role="alert">
                                {errorMessage}
                            </p>
                        ) : (
                            ""
                        )}
                    </div>
                    <div style={{ display: !emailSend ? "none" : "flex" }} className="form-login">
                        <img src={myConfig.brandinglogoBase64} style={{ marginBottom: "25px", display: myConfig.brandinglogoBase64.length <= 0 ? "none" : "" }} width="50%" />
                        <h1 className="h1-login " style={{ color: myConfig.brandingColor, fontSize: '2rem' }}>Portal de Pacientes</h1>
                        <div className="social-container">
                        </div>
                        <div style={{ width: '100%', textAlign: "justify", fontWeight: "bold", color:"#898382", fontSize:"14px" }}>
                            <p>La información para recuperar su contraseña
                                fue enviada a su correo electrónico, por favor 
                                verificar su bandeja de entrada.
                                </p>
                        </div>
                        <button
                            style={{fontSize:"16px", textAlign:"center"}}
                            className="button-login mt-3"
                            type="button" style={{ backgroundColor: myConfig.brandingColor, borderColor: myConfig.brandingColor }}
                            onClick={(e) => {
                                history.push("/login");
                            }}
                        >
                            OK
                        </button>
                    </div>

                </div>
                <div className="overlay-container">
                    <div className="overlay" style={{ background: myConfig.brandingColor }}>
                        <div className="overlay-panel overlay-left">
                            <h1>Welcome Back!</h1>
                            <p>To keep connected with us please login with your personal info</p>
                            <button className="button-login ghost" id="signIn" >Sign In</button>
                        </div>
                        <div className="overlay-panel overlay-right">
                            <h1 className="h1-login">¡Bienvenido(a)!</h1>
                            <p className="p-login">Ingrese sus datos personales y consulte sus resultados.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

RecuperarContrasena.propTypes = {
    recuperarContrasena: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    setState: PropTypes.object,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
    recuperarContrasena,
    setState,
})(RecuperarContrasena);
