import 'react-datepicker/dist/react-datepicker.css'
import React, { useState, useEffect, Fragment } from "react";
import { connect, useSelector } from "react-redux"
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import PropTypes from "prop-types";

import {
    Card, CardBody, CardTitle, Row, Col,
    Button,
} from 'reactstrap'
import moment from 'moment';
import ItemResultado from './itemResultado'
import style from './home.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { getAll, getAllMore, setState } from "../action/resultados";
import InfiniteScroll from "react-infinite-scroll-component";


const HomeResultados = ({ getAll, getAllMore, setState }) => {
    let [myConfig, setMyConfig] = useState(window.myConfig);
    var [filter, setFilter] = useState({ search: "", fechaDesde: null, fechaHasta: null, page: 1, length: 35, fechaDesdeFilter: null, fechaHastaFilter: null, });

    useEffect(() => {
        setState();
        getAll(filter);
    }, [setState, getAll]);

    const onSearch = () => {
        filter.page = 1;
        getAll(filter);
    };

    const onSearchMore = () => {
        setFilter(prevState => ({
            ...prevState,
            page: prevState.page + 1
        }));
        getAllMore(filter);
    };



    const items = useSelector((state) => state.resultado.items);
    const disabled = useSelector((state) => state.resultado.disabled);

    return (
        <div>
            <Card className={style.homeCard}>
                <CardBody>
                    <CardTitle className={style.homeCardTitle} style={{ color: myConfig.brandingColor }} tag="h5">
                        Lista de resultados
                    </CardTitle>
                    <hr />
                    <Row>

                        <Col style={{marginBottom: "10px"}} lg="4">
                            <input className="form-control"
                                type="search"
                                onChange={(e) => {
                                    setFilter(prevState => ({
                                        ...prevState,
                                        search: e.target.value
                                    }));
                                }}

                                placeholder="Buscar por nombre y número de orden" />
                        </Col>
                        <Col style={{ marginBottom: "10px" }}  lg="3">
                            <DatePicker
                                name="fechaDesde"
                                placeholderText='Fecha desde'
                                locale={es}
                                selected={filter.fechaDesdeFilter}
                                selectsStart
                                startDate={filter.fechaDesdeFilter}
                                endDate={filter.fechaHastaFilter}
                                dateFormat="dd/MM/yyyy"
                                className='form-control'
                                customInput={
                                    <InputMask
                                        type="text"
                                        placeholder='Fecha hasta'
                                        mask="99/99/9999"
                                        maskChar=""
                                    />
                                }
                                onChange={(date) => {
                                    setFilter(prevState => ({
                                        ...prevState,
                                        fechaDesde: date === null ? null : moment(date).format("YYYY-MM-DD"),
                                        fechaDesdeFilter: date
                                    }));
                                }}
                            />
                        </Col>
                        <Col style={{ marginBottom: "10px" }} lg="3">
                            <DatePicker
                                name="fechaHasta"
                                locale={es}
                                selected={filter.fechaHastaFilter}
                                customInput={
                                    <InputMask
                                        type="text"
                                        placeholder='Fecha hasta'
                                        mask="99/99/9999"
                                        maskChar=""
                                    />
                                }
                                dateFormat="dd/MM/yyyy"
                                className='form-control'
                                placeholderText='Fecha hasta'
                                selectsEnd
                                startDate={filter.fechaDesdeFilter}
                                endDate={filter.fechaHastaFilter}
                                minDate={filter.fechaDesdeFilter}
                                onChange={(date) => {
                                    setFilter(prevState => ({
                                        ...prevState,
                                        fechaHasta: date === null ? null : moment(date).format("YYYY-MM-DD"),
                                        fechaHastaFilter: date
                                    }));
                                }}
                            />
                        </Col>
                        <Col style={{ marginBottom: "10px" }} lg="2">
                            <Button className={style.buttonHome} style={{ backgroundColor: myConfig.brandingColor, borderColor: myConfig.brandingColor }}
                                onClick={(e) => {
                                    setFilter(prevState => ({
                                        ...prevState,
                                        page: 1
                                    }));
                                    onSearch();
                                }}
                                type="button" >
                                <span style={{ marginRight: "5px", fontSize: "14px" }}>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                                Buscar
                            </Button>
                        </Col>

                    </Row>
                    <div style={{ textAlign: 'left', marginTop: "25px" }} className={style.gridContainer}>
                        <div>
                            <h6>{items.length} resultados</h6>
                        </div>
                    </div>
                    <InfiniteScroll
                        dataLength={items.length}
                        className={style.gridContainer}
                        next={onSearchMore}
                        style={{ overflow: 'none !important' }}
                        hasMore={!disabled}
                    >
                        {items.map((item, index) => (
                            <ItemResultado key={index} item={item}></ItemResultado>
                        ))}
                    </InfiniteScroll>
                </CardBody>
            </Card>
        </div>
    );
};

HomeResultados.propTypes = {};

const mapStateToProps = (state) => ({
    getAll: PropTypes.object.isRequired,
    getAllMore: PropTypes.object.isRequired,
    setState: PropTypes.object,
});

const mapDispatchToProps = {
    getAll,
    getAllMore,
    setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeResultados);

