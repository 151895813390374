import {
    PROFILE_CHANGE_PASSWORD_SUCCESS,
    PROFILE_CHANGE_PASSWORD_FAIL,
    PROFILE_SET_CHANGE_PASSWORD_INITIAL,
} from "../action/types";

const initialState = {
    confirmMessage: null,
    typeMessage: "text-danger",
    emailSend: false,
    isChangePassword: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case PROFILE_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                ...payload,
                typeMessage:"text-success",
                confirmMessage: payload.confirmMessage,
            };

        case PROFILE_CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                ...payload,
                typeMessage: "text-danger",
                confirmMessage: payload.confirmMessage,
            };

        case PROFILE_SET_CHANGE_PASSWORD_INITIAL:
            return {
                ...state,
                ...payload,
            };
        default: {
            return state;
        }
    }
}
