
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { auth, removeConfirmMessage } from "../../action/auth";
import { Redirect, Link } from "react-router-dom";
import { BackgroundSyncPlugin } from "workbox-background-sync";

const Login = ({ auth, isAuthenticated, removeConfirmMessage }) => {
    require('./Login.css')
    console.log(window.myConfig);
    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");
    let [requiredUsername, setRequiredUsername] = useState("");
    let [requiredPassword, setRequiredPassword] = useState("");
    let [myConfig, setMyConfig] = useState(window.myConfig);

    useEffect(() => {
        removeConfirmMessage();
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        if (!username.trim()) {
            setRequiredUsername("Favor introduzca el nombre del usuario.");
        } else if (!password.trim()) {
            setRequiredPassword("Favor introduzca la contraseña del usuario.");
        } else auth({ username, password });
    };

    const onEnter = (e) => {
        if (e.key === "Enter") {
            if (!username.trim()) {
                setRequiredUsername("Favor introduzca el nombre del usuario.");
            } else if (!password.trim()) {
                setRequiredPassword("Favor introduzca la contraseña del usuario.");
                return;
            } else auth({ username, password});
        }
    };

    const removeRequiredMessage = () => {
        setRequiredUsername("");
        setRequiredPassword("");
    };

    let errorMessage = useSelector((state) => state.auth.confirmMessage);
    //Redirect if logged in
    if (isAuthenticated) {
        return <Redirect to="/" />;
    }



    return (
        <div className="login-body" style={{ width: '100%', height: '100%' }}>
       
            <div className="container container-login" id="container" >
                
                <div className="form-container sign-in-container">
                    <form className="form-login" action="#">
                        <img src={myConfig.brandinglogoBase64} style={{ marginBottom: "25px", display: myConfig.brandinglogoBase64.length<=0? "none":"" }} width="50%" />
                        <h1 className="h1-login " style={{ color: myConfig.brandingColor, fontSize: '2rem' }}>Portal de Pacientes</h1>
                        <div className="social-container">
                        </div>
                        <div style={{ width: '100%' }}>
                            <input className="input-login" type="text" placeholder="Nombre de usuario" required
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                    removeConfirmMessage();
                                    removeRequiredMessage();
                                }}
                                value={username} />
                            {requiredUsername ? (
                                <p className="p-login text-danger" style={{ margin: '0' }} role="alert">
                                    {requiredUsername}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                        <div style={{ width: '100%' }}>
                            <input className="input-login" type="password" placeholder="Contraseña" onChange={(e) => {
                                setPassword(e.target.value);
                                removeConfirmMessage();
                                removeRequiredMessage();
                            }}
                                onKeyPress={(e) => onEnter(e)}
                                value={password} />
                            {requiredPassword ? (
                                <p className="p-login text-danger" style={{ margin: '0' }} role="alert">
                                    {requiredPassword}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                        <Link className="a-login" to={"/recuperarcontrasena"}>
                            ¿Olvidó su contraseña?
                        </Link>
                        <p></p>
                        <button className="button-login" style={{ backgroundColor: myConfig.brandingColor, borderColor: myConfig.brandingColor }} type="button" onClick={(e) => onSubmit(e)}>Iniciar Sesión</button>
                        {errorMessage ? (
                            <p className="text-danger mt-3" role="alert">
                                {errorMessage}
                            </p>
                        ) : (
                            ""
                        )}
                    </form>
                    
                </div>
                <div className="overlay-container">
                    <div className="overlay" style={{ background: myConfig.brandingColor }}>
                        <div className="overlay-panel overlay-left">
                            <h1>Welcome Back!</h1>
                            <p>To keep connected with us please login with your personal info</p>
                            <button className="button-login ghost" id="signIn" >Sign In</button>
                        </div>
                        <div className="overlay-panel overlay-right">
                            <h1 className="h1-login" style={{marginTop:"20px"}}>¡Bienvenido(a)!</h1>
                            <p className="p-login">Ingrese sus datos personales y consulte sus resultados.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Login.propTypes = {
    auth: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { auth, removeConfirmMessage })(Login);
