import React, { Fragment, Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import store from "./store";
import { verifyToken, setCurrentUser, logout } from "./action/auth";
import Routes from "./components/routing/Routes";
import { ToastProvider } from "react-toast-notifications";
import jwt_decode from "jwt-decode"
import { Spinner } from "./components/common/spinner";


if (localStorage.token) {
    const decoded = jwt_decode(localStorage.token);
    store.dispatch(setCurrentUser(decoded));

    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
        store.dispatch(logout());
        window.location.href = "/login";
    } else {
        store.dispatch(verifyToken(decoded));
    }
}

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <ToastProvider autoDismiss={true}>
                    <Router>
                            <Route component={Routes} />
                        <Spinner/>
                    </Router>
                </ToastProvider>
            </Provider>
        );
    }
}

export default App;
