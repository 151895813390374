import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faEye } from '@fortawesome/free-solid-svg-icons'

import { download, preview } from "../api";
import './itemResultado.css'

const ItemResultado = ({ item }) => {
    let [myConfig, setMyConfig] = useState(window.myConfig);
    const onDownload = (resultadoId, numeroOrden) => download(`/api/Resultado/items/download?id=${resultadoId}`, numeroOrden);

    const onPreview = (resultadoId,url) => preview(`/api/Resultado/items/view?id=${resultadoId}`,url);

    return (
        <Fragment>
            <div className="item-resultado-card">
                <div>
                    <div style={{textAlign:"left", float:"left", width:"50%", fontWeight:"bold"}}> {item.numeroOrden}</div>
                    <div style={{ textAlign: "right", float: "left", width: "45%", marginLeft: "4%" }}> {<span className={item.estadoStyle}>{ item.estado}</span>}</div>
                </div>
                <div style={{ textAlign: "left", width: "100%", marginTop: "25px", fontWeight: "bold", fontSize:"13px" }}>{item.nombreCompleto} </div>
                <p className="text-muted" style={{ margin: "0", marginTop: "3px", fontSize: "12px" }}>Fecha de resultados: {moment(item.fechaResultado).format("DD/MM/YYYY")}</p>
                <div style={{textAlign: "right", marginTop: "20px"}}>
                    <button disabled={item.estado === "Pendiente" } style={{ marginRight: "10px" }} className="btn btn-secondary btn-sm btn-xs"
                        onClick={(e) => {
                            onPreview(item.id,item.url);
                        }}                     >
                        <span style={{ marginRight: "5px"}}><FontAwesomeIcon icon={faEye} /></span>
                        Ver
                         </button>
                    <button disabled={item.estado === "Pendiente"} style={{ backgroundColor: myConfig.brandingColor, borderColor: myConfig.brandingColor }} className="btn btn-primary button-item-result btn-sm btn-xs"
                        onClick={(e) => {
                            onDownload(item.id, item.numeroOrden);
                        }} >
                        <span style={{ marginRight: "5px" }}><FontAwesomeIcon icon={faFilePdf} /></span>
                        Descargar
                        </button>
                </div>
            </div>
        </Fragment>
    );
};



export default connect(null, {})(ItemResultado);