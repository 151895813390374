import { combineReducers } from "redux";
import auth from "./auth";
import resultado from "./resultado";
import profile from "./profile";

export default combineReducers({
    auth,
    resultado,
    profile
});
