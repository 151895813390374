import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import {
    cambiarContrasena,
    getUserKeyChangePassword,
    removeConfirmMessage,
} from "../../action/auth";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(
            8,
            "La contraseña debe tener mayúsculas y minúsculas, Números, caracteres especiales como !#@$&* y un mínimo de 8 caracteres. Ejemplo. Tj@2039Hw!"
        )
        .required(
            "La contraseña debe tener mayúsculas y minúsculas, Números, caracteres especiales como !#@$&* y un mínimo de 8 caracteres. Ejemplo. Tj@2039Hw!"
        )
        .matches(
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[!#@$&*])/,
            "La contraseña debe tener mayúsculas y minúsculas, Números, caracteres especiales como !#@$&* y un mínimo de 8 caracteres. Ejemplo. Tj@2039Hw!"
        ),
    confirmPassword: Yup.string()
        .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                "La contraseña y la confirmación no coinciden"
            ),
        })
        .required("Es requerido confirmar la contraseña"),
});

const CambiarContrasena = ({
    auth,
    isAuthenticated,
    removeConfirmMessage,
    cambiarContrasena,
    getUserKeyChangePassword,
    history,
    match,
}) => {

    require('./Login.css')
    const [myKey, setMyKey] = useState("");
    useEffect(() => {
        const getUserKey = async () => {
            const { key } = match.params;
            setMyKey(key);
            getUserKeyChangePassword(key);
        };
        removeConfirmMessage();
        getUserKey();
    }, []);

    let errorMessage = useSelector((state) => state.auth.confirmMessage);

    let typeMessage = useSelector((state) => state.auth.typeMessage);
    let [myConfig, setMyConfig] = useState(window.myConfig);

    return (
        <div className="login-body" style={{ width: '100%', height: '100%' }}>
            <div className="container container-login" id="container" >
                <div className="form-container sign-in-container">
                    <div className="form-login">
                        <img src={myConfig.brandinglogoBase64} style={{ marginBottom: "25px", display: myConfig.brandinglogoBase64.length <= 0 ? "none" : "" }} width="50%" />
                        <h1 className="h1-login " style={{ color: myConfig.brandingColor, fontSize: '2rem' }}>Portal de Pacientes</h1>
                        <div className="social-container">
                        </div>
                        <Formik
                            initialValues={{
                                password: "",
                                confirmPassword: "",
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                let model = {
                                    key: myKey,
                                    password: values.password,
                                };

                                removeConfirmMessage();
                                cambiarContrasena(model, history);
                                setSubmitting(false);
                            }}
                            validationSchema={validationSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form style={{ width: "100%" }} className="user">
                                    <div style={{ width: "100%" }}>
                                        <Field
                                            type="password"
                                            name="password"
                                            autoComplete="chrome-off"
                                            placeholder="Nueva contraseña"
                                            className={`input-login ${touched.password && errors.password
                                                ? "is-invalid"
                                                : touched.password && !errors.password
                                                    ? "is-valid"
                                                    : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                            component="p"
                                            name="password"
                                            className="text-danger mt-3"
                                        />
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <Field
                                            type="password"
                                            name="confirmPassword"
                                            autoComplete="chrome-off"
                                            placeholder="Confirmar nueva contraseña"
                                            className={`input-login ${touched.confirmPassword && errors.confirmPassword
                                                ? "is-invalid"
                                                : touched.confirmPassword && !errors.confirmPassword
                                                    ? "is-valid"
                                                    : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                            component="p"
                                            name="confirmPassword"
                                            className="text-danger mt-3"
                                        />
                                    </div>


                                    <button
                                        disabled={isSubmitting}
                                        className="button-login mt-3"
                                        type="submit" style={{ backgroundColor: myConfig.brandingColor, borderColor: myConfig.brandingColor }}
                                    >
                                        Recuperar contraseña
                                    </button>
                                </Form>
                            )}
                        </Formik>
                        {errorMessage ? (
                            <p className={`${typeMessage} mt-3`} role="alert">
                                {errorMessage}
                            </p>
                        ) : (
                            ""
                        )}
                    </div>

                </div>
                <div className="overlay-container">
                    <div className="overlay" style={{ background: myConfig.brandingColor }}>
                        <div className="overlay-panel overlay-left">
                            <h1>Welcome Back!</h1>
                            <p>To keep connected with us please login with your personal info</p>
                            <button className="button-login ghost" id="signIn" >Sign In</button>
                        </div>
                        <div className="overlay-panel overlay-right">
                            <h1 className="h1-login">¡Bienvenido(a)!</h1>
                            <p className="p-login">Ingrese sus datos personales y consulte sus resultados.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CambiarContrasena.propTypes = {
    cambiarContrasena: PropTypes.func.isRequired,
    getUserKeyChangePassword: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
    getUserKeyChangePassword,
    cambiarContrasena,
    removeConfirmMessage,
})(CambiarContrasena);
