
import { trackPromise } from "react-promise-tracker";
import printJS from 'print-js'

let apiUrl = window.myConfig.apiUrl;
export const apiGet = (url) => {
    const token = localStorage.getItem("token");

    let header = {
        "Content-type": "application/json",
        Authorization: `${token ? `bearer ${token}` : ""}`,
    };

    return trackPromise(
        fetch(`${window.myConfig.apiUrl}${url}`, {
            method: "GET",
            headers: new Headers(header),
        }).then((v) => v.json())
    );
};

export const apiPost = (url, obj) => {
    const token = localStorage.getItem("token");

    let header = {
        "Content-type": "application/json",
        //"Access-Control-Allow-Origin": "*",
        Authorization: `${token ? `bearer ${token}` : ""}`,
    };

    return trackPromise(
        fetch(`${window.myConfig.apiUrl}${url}`, {
            method: "POST",
            body: JSON.stringify(obj),
            headers: new Headers(header),
            //mode: "cors",
        })
            .then((v) => v.json())
            .then((r) => {
                if (r.error) {
                    return Promise.reject(r.validation);
                }
                return r;
            })
    );
};

export const apiPut = (url, id, obj) => {
    const token = localStorage.getItem("token");

    let header = {
        "Content-type": "application/json",
        //"Access-Control-Allow-Origin": "*",
        Authorization: `${token ? `bearer ${token}` : ""}`,
    };
    console.log(`${window.myConfig.apiUrl}${url}/${id}`);
    return trackPromise(
        fetch(`${apiUrl}${url}/${id}`, {
            method: "PUT",
            body: JSON.stringify(obj),
            headers: new Headers(header),
            //mode: "cors",
        })
            .then((v) => v.json())
            .then((r) => {
                if (r.error) {
                    return Promise.reject(r.validation);
                }
                return r;
            })
    );
};

export const apiDelete = (url, id) => {
    const token = localStorage.getItem("token");

    let header = {
        "Content-type": "application/json",
        Authorization: `${token ? `bearer ${token}` : ""}`,
    };

    return trackPromise(
        fetch(`${window.myConfig.apiUrl}${url}/${id}`, {
            method: "DELETE",
            headers: new Headers(header),
        })
            .then((v) => v.json())
            .then((r) => {
                if (r.error) {
                    return Promise.reject(r.validation);
                }
                return r;
            })
    );
};

export const download = (url,fileName) => {
    const token = localStorage.getItem("token");

    let header = {
        "Content-type": "application/json",
        Authorization: `${token ? `bearer ${token}` : ""}`,
    };


    trackPromise(fetch(`${window.myConfig.apiUrl}${url}`, {
        method: "GET",
        headers: new Headers(header),
    })
        .then((v) => v.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${fileName}.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        }));

};

export const preview = (url,urlEncrypted) => {
    const token = localStorage.getItem("token");


    var userAgent = navigator.userAgent.toLowerCase();
    var isAndroid = userAgent.indexOf("android") > -1;
    var isChrome = userAgent.indexOf("chrome") > -1;
    console.log(userAgent);

    console.log(isAndroid);
    console.log(isChrome);
    if (isChrome && !isAndroid) 
    {
        let header = {
            "Content-type": "application/json",
            Authorization: `${token ? `bearer ${token}` : ""}`,
        };

        trackPromise(fetch(`${window.myConfig.apiUrl}${url}`, {
            method: "GET",
            headers: new Headers(header),
        })
            .then((v) => v.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob], { type: "application/pdf" }),
                );

                printJS({ printable: url, type: 'pdf' });

            }));
    }
    else
        window.open(`https://docs.google.com/gview?url=${urlEncrypted}&embedded=true`);
   
};
