import React, {  Link, useState } from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import NavMenu from './NavMenu';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import logo from './logo.png'

const Layout = ({ auth: { isAuthenticated }, children }) => {
    let [myConfig, setMyConfig] = useState(window.myConfig);
    const _layout = (
        <div style={{ width: '100%', display: isAuthenticated ? "block" : "none" }}>
            <NavMenu />
            <Container fluid="true">
                <div style={{ margin: '30px' }}>
                    {children}
                </div>
            </Container>
            <div style={{ width: "100%", display: "grid", gridTemplateColumns:"50% 50%"}}>
                <div style={{ marginBottom: "15px" }}>          
                    <img style={{ height: "55px", marginLeft: "20px", width: "100%", maxWidth: "200px" }} src={logo} alt="Labplus Online"></img>
                    <img src={myConfig.brandinglogoBase64} style={{ height: "55px", marginLeft: "20px", width: "100%", maxWidth: "200px", display: myConfig.brandinglogoBase64.length <= 0 ? "none" : "" }} />
                </div>
                <div style={{ textAlign: "right" }}>
                    <p style={{margin:0, marginTop:"15px", marginRight:"30px"}}>Bio-Nuclear Labplus Online. Todos los derechos reservados {new Date().getFullYear()}</p>
                </div>
            </div>
        </div>
    );

    return _layout;
};

Layout.propTypes = {
    auth: PropTypes.object.isRequired,
    children: PropTypes.node,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(Layout);
