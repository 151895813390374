import React, { useState} from 'react';
import './NavMenu.css';
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavbarText,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { logout } from "../../action/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import 'bootstrap/dist/css/bootstrap.min.css';

const NavMenu = ({ auth: { isAuthenticated }, logout }) => {
    let [myConfig, setMyConfig] = useState(window.myConfig);
    let nombreCompleto = "";
    if (localStorage.token) {
        const decoded = jwt_decode(localStorage.token);
        nombreCompleto = decoded.NombreCompleto;

    }
    const _navMenu = (
        <header>
            <Navbar className=" mb-3 navbar-colors" style={{ backgroundColor: myConfig.brandingColor, paddingLeft: "10px", paddingRight: "10px" }} expand="md" light>
                <NavbarBrand style={{ textAlign: 'left' }} tag={Link} to="/">
                    {/*<img src={myConfig.brandinglogoBase64} style={{ display: myConfig.brandinglogoBase64.length <= 0 ? "none" : "" }} width="30%" />*/}
                    Portal de Pacientes</NavbarBrand>
                <Collapse  className="show justify-content-end" navbar>
                    <Nav navbar>
                        <UncontrolledDropdown
                            inNavbar
                            nav>
                            <DropdownToggle
                                caret
                                nav
                                style={{ color: "white" }}>
                                Hola, {nombreCompleto}
                            </DropdownToggle>
                            <DropdownMenu right style={{ right:"5%", minWidth:"16rem", textAlign:"center" }} >
                                <DropdownItem onClick={() => window.location.href = "/usercambiopass"}>
                                    <span style={{ marginRight: "5px", fontSize: "14px" }}>
                                        <FontAwesomeIcon icon={faEllipsis} /></span>Cambiar contraseña
                                </DropdownItem>
                                <DropdownItem onClick={() => logout()}>
                                    <span style={{ marginRight: "5px", fontSize: "14px" }}>
                                        <FontAwesomeIcon icon={faArrowRightFromBracket} /></span>Salir
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Collapse>

            </Navbar>
        </header>
    );

    return (isAuthenticated) ? _navMenu : <Redirect to="/login" />;
};

NavMenu.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps, { logout })(NavMenu);