import {
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_TOKEN_SUCCESS,
    AUTH_TOKEN_FAIL,
    LOGOUT_SUCESSS,
    SET_CURRENT_USER,
    REMOVE_CONFIRM_MESSAGE,
    PASSWORD_RECOVER_SUCCESS,
    PASSWORD_RECOVER_FAIL,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,
    GET_CHANGE_PASSWORD_FAIL,
    GET_CHANGE_PASSWORD_SUCCESS,
    RECUPERAR_PASSWORD_SET_INITIAL,
} from "./types";

import { apiPost, apiGet } from "../api";

export const setState = () => async (dispatch) => {
    dispatch({ type: RECUPERAR_PASSWORD_SET_INITIAL, payload: [] });
};

export const auth = ({ username, password }) => async (dispatch) => {
    try {

        let result = await apiPost("/api/Auth/token", {
            Username: username,
            Password: password,
            Domain: window.myConfig.domain,
        });
        if (result.commit) {
            localStorage.setItem("token", result.token);
            localStorage.setItem("usuario", username);
            dispatch({
                type: AUTH_SUCCESS,
                payload: {
                    username,
                    password,
                    token: result.token,
                },
            });
        } else {
            dispatch({
                type: AUTH_FAIL,
                payload: {
                    confirmMessage: result.message,
                },
            });
        }
    } catch (error) {
        dispatch({ type: AUTH_FAIL });
    }
};

export const verifyToken = (decoded) => async (dispatch) => {
    if (localStorage.token) {
        const { UserName, Email } = decoded;

        dispatch({
            type: AUTH_TOKEN_SUCCESS,
            payload: {
                UserName,
                Email,
            },
        });
    } else
        dispatch({
            type: AUTH_TOKEN_FAIL,
            payload: {},
        });
};

export const logout = () => async (dispatch) => {
    dispatch({ type: LOGOUT_SUCESSS });
    window.location.href = "/login";
};

export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};

export const removeConfirmMessage = () => async (dispatch) => {
    dispatch({ type: REMOVE_CONFIRM_MESSAGE });
};

export const recuperarContrasena = (model, history) => async (dispatch) => {
    const { protocol, host } = window.location;
    const url = `${protocol}//${host}/cambiarcontrasena/`;

    try {
        let result = await apiPost(
            `/api/Auth/sendforgotpassword?email=${model.Email}&url=${url}&domain=${window.myConfig.domain}`,
            {}
        );

        if (result.commit) {
            dispatch({
                type: PASSWORD_RECOVER_SUCCESS,
                payload: {
                    confirmMessage: "Correo de reestablecimiento de contraseña enviado",
                    typeMessage: "text-primary",
                },
            });

            //setTimeout(function () {
            //  dispatch({ type: REMOVE_CONFIRM_MESSAGE });
            //  history.push("/login");
            //}, 3000);
        } else {
            dispatch({
                type: PASSWORD_RECOVER_FAIL,
                payload: {
                    confirmMessage: result.confirm_Message,
                },
            });
        }
    } catch (err) {
        console.log(err);
    }
};

export const cambiarContrasena = (model, history) => async (dispatch) => {
    try {
        let result = await apiPost(
            `/api/Auth/forgotpasswordchange?key=${model.key}&newpassword=${model.password}`,
            {}
        );

        if (result.commit) {
            dispatch({
                type: CHANGE_PASSWORD_SUCCESS,
                payload: {
                    confirmMessage: "Su contraseña ha sido cambiada satisfactoriamente.",
                    typeMessage: "text-primary",
                },
            });

            setTimeout(function () {
                dispatch({ type: REMOVE_CONFIRM_MESSAGE });
                history.push("/login");
            }, 3000);
        } else {
            dispatch({
                type: CHANGE_PASSWORD_FAIL,
                payload: {
                    confirmMessage: result.confirm_Message,
                },
            });
        }
    } catch (err) {
        console.log(err);
    }
};

export const getUserKeyChangePassword = (key) => async (dispatch) => {
    try {
        let result = await apiGet(`/api/Auth/getuserkey?key=${key}`);

        if (result.commit) {
            const {
                data: { id, email },
            } = result;
            dispatch({
                type: GET_CHANGE_PASSWORD_SUCCESS,
                payload: { id, email },
            });
        } else {
            dispatch({
                type: GET_CHANGE_PASSWORD_FAIL,
                payload: {
                    confirmMessage: result.confirm_Message,
                },
            });
        }
    } catch (err) {
        console.log(err);
    }
};
