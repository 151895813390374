import {
    RESULTADO_SETEAR_LISTA,
    RESULTADO_LISTA_SUCCESS,
    RESULTADO_LISTA_FAIL,
    RESULTADO_LISTA_MAS_SUCCESS,
    RESULTADO_LISTA_MAS_FAIL,
} from "./types";
import { apiPost } from "../api";

export const setState = () => async (dispatch) => {
    dispatch({ type: RESULTADO_SETEAR_LISTA, payload: [] });
};

export const getAll = (filter) => async (dispatch) => {
    try {
        await apiPost(
            `/api/Resultado/items`,
            filter
        ).then((data) => {
            dispatch({ type: RESULTADO_LISTA_SUCCESS, payload: data });
        });
    } catch (err) {
        dispatch({ type: RESULTADO_LISTA_FAIL });
    }
};

export const getAllMore = (filter) => async (dispatch) => {
    try {
        await apiPost(
            `/api/Resultado/items`,
            filter
        ).then((data) => {
            dispatch({ type: RESULTADO_LISTA_MAS_SUCCESS, payload: data });
        });
    } catch (err) {
        dispatch({ type: RESULTADO_LISTA_MAS_FAIL });
    }
};

//export const create = (obj, history) => async (dispatch) => {
//    try {
//        Swal.fire({
//            title: "Suscripción",
//            text: "¿Estás seguro de que deseas realizar esta acción?",
//            type: "primary",
//            showCancelButton: true,
//            confirmButtonColor: "#3085d6",
//            cancelButtonColor: "#d33",
//            confirmButtonText: "Si",
//            cancelButtonText: "No",
//        }).then((result) => {
//            if (result.value) {
//                apiPost(`/api/suscripcion`, obj).then((data) => {
//                    if (data.commit) {
//                        dispatch({ type: SUSCRIPCION_AGREGAR_SUCCESS });
//                        Swal.fire("Suscripción", data.confirm_Message, "success");
//                        history.push("/configuracion/suscripciones");
//                    } else {
//                        dispatch({ type: SUSCRIPCION_AGREGAR_FAIL });
//                        console.error(data);
//                        Swal.fire("Suscripción", data.confirm_Message, "warning");
//                    }
//                });
//            }
//        });
//    } catch (err) {
//        console.error(err);
//        Swal.fire(
//            "Suscripción",
//            "Hubo un error al intentar crear la suscripción",
//            "error"
//        );
//        dispatch({ type: SUSCRIPCION_AGREGAR_FAIL });
//    }
//};




